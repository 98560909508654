.thank-page {
  @include flex(between, column);

  flex: 1;
  height: 100%;
  overflow: hidden;

  &__wrapper {
    @include size(100%);

    position: relative;
    display: flex;
    align-items: flex-end;
    max-width: 1168px;
    flex: 1;
    box-sizing: border-box;
    margin: 0 auto;
  }

  &__wrap {
    @include size(100%);

    box-sizing: border-box;
    padding: 32px 32px 75px;
    @include breakpoint(lg) {
      padding: 60px 32px 24px;
      max-height: 827px;
      box-sizing: border-box;
      margin-bottom: auto;
    }

    @include breakpoint {
      padding: 100px 16px 24px;
      max-height: 650px;
    }
  }

  &__figure {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
    background: transparent  center / contain no-repeat;

    img {
      @include size(100%);
    }

    &:after {
      @include size(100%);

      position: absolute;
      top: 0;
      left: 0;
      background-image: radial-gradient(46.71% 47.78% at 50% 50%, rgba(0, 0, 0, 0) 38%, var(--main-bg-color) 100%);
      content: '';
    }
  }

  &__inner-wrap {
    position: relative;
    z-index: 1;
    border: 1px solid #DFD7FD;
    border-radius: 32px;
    max-width: 819px;
    width: 100%;
    min-height: 264px;
    padding: 40px 64px;
    box-sizing: border-box;
    margin: 0 auto;
    box-shadow: inset -300px 300px 100px 0px rgba(255, 255, 255, 0.09), inset 300px 400px 300px 0px rgba(165, 165, 165, 0.08);

    @include breakpoint(lg) {
      padding: 32px;
    }

    @include breakpoint {
      padding: 32px 20px;
    }
  }

  &__title {
    @extend %title-big;

    font-weight: 500;
    text-align: center;
    margin-bottom: 24px;

    @include breakpoint(lg) {
      max-width: 352px;
      margin: 0 auto 24px;
    }

    @include breakpoint {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      text-align: left;

    }
  }

  &__text {
    @extend %text;

    text-align: center;
    margin-bottom: 48px;

    @include breakpoint {
      font-size: 18px;
      line-height: 22px;
      text-align: left;

    }
  }

  &__subtext {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #AA8FF6;
    margin-bottom: 24px;
  }

  &__text_wrap {
    margin: -24px auto 24px;
  }

  &__cred_text {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #fff;
    margin: 4px auto;
    text-align: center;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__text_counter {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #fff;
    margin: 12px auto;
    text-align: center;

    span {
      margin: 0 4px;
      color: #DC5A32
    }
  }

  &__button {
    @include flex(center);

    width: 100%;
    min-height: 56px;
    border-radius: 12px;
    background-color: var(--them-color);
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #fff;

    span {
      margin: 0 4px;
      color: #DC5A32
    }

    &--disable {
      pointer-events: none;
    }
  }

  &--chat-quiz {

    .thank-page {
      &__wrap {
        position: relative;
        z-index: 1;
        padding: 0;
      }

      &__figure {
        position: static;
        transform: translateX(0);
        width: 100%;
        max-width: 1183px;
        max-height: 789px;
        height: auto;

        &:after {
          display: none;
        }

        img {
          object-fit: cover;
          object-position: center center;
        }

        @include breakpoint(lg) {
          height: 512px;
          margin-bottom: 48px;
        }

        @include breakpoint {
          $indent: 16px;

          width: calc(100% + #{$indent * 2});
          height: 416px;
          margin: -44px (-$indent) auto (-$indent);

          &:after {
            background-image: radial-gradient(101.81% 45.26% at 50% 50%, rgba(17, 15, 23, 0) 0%, var(--main-bg-color) 100%);
          }
        }
      }

      &__wrapper {
        max-width: 100%;
        align-items: center;

        @include breakpoint(lg) {
          flex-direction: column;
        }

        @include breakpoint {
          padding: 0 16px;
        }
      }

      &__title {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
        color: #FFFFFF;
        text-align: left;

        @include breakpoint(lg) {
          margin: 0 0 8px 0;
        }

        @include breakpoint {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
        }
      }

      &__inner-wrap {
        max-width: 460px;
        padding: 24px;
        margin: 0 0 0 5px;
        box-sizing: border-box;
        background: rgba(23, 23, 23, 0.6);
        border: 1px solid rgba(0, 0, 0, 0.25);
        box-shadow: inset -2px 2px 11.1333px rgba(165, 165, 165, 0.075), inset -2px 2px 11.1333px rgba(255, 255, 255, 0.085);
        backdrop-filter: blur(17.9247px);
        border-radius: 16px;
        position: sticky;
        bottom: 24px;
        flex-shrink: 0;

        @include breakpoint(lg) {
          max-width: 580px;
        }

        @include breakpoint {
          margin-bottom: 32px;
          padding: 16px;
          flex-shrink: 0;
        }
      }

      &__text {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
        text-align: left;
        color: #D1D1D1;

        @include breakpoint {
          font-size: 13px;
          line-height: 20px;
        }
      }

      &__button {
        min-height: 48px;

        span {
          color: #AA8FF6;
        }
      }
    }

    @include breakpoint(lg) {
      position: relative;

      &:after {
        position: absolute;
        width: 100%;
        height: 600px;
        content: '';
        bottom: 0;
        right: 0;
        background: transparent url('../images/stars-bg.svg') left bottom / cover no-repeat;
      }
    }

    @include breakpoint {
      &:after {
       height: 500px;
      }
    }
  }

  &--white {
    .thank-page {
      &__wrapper {
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @include breakpoint(lg) {
          align-items: flex-end;
        }
      }

      &__wrap {
        padding: 32px 130px 75px;

        @include breakpoint(lg) {
          padding: 0 32px 102px;
          max-height: 100%;
        }

        @include breakpoint {
          padding: 0 16px 48px;
        }
      }

      &__figure {
        position: fixed;
        width: 1225px;
        height: 929px;
        top: 0;
        left: 0;
        transform: translate(0);

        &:after {
          display: none;
        }

        @include breakpoint(lg) {
          width: 957px;
          height: 638px;
          left: -32px;
          top: 73px;
          position: absolute;
        }

        @include breakpoint {
          width: 593px;
          height: 396px;
          top: 60px;
        }
      }

      &__inner-wrap {
        margin: 0;
        background: rgba(#F7F7F7, 0.21);
        border: 1px solid #DFD7FD;
        box-shadow: inset -2px 2px 11.1333px rgba(165, 165, 165, 0.075), inset -2px 2px 11.1333px rgba(255, 255, 255, 0.085);
        backdrop-filter: blur(17.9247px);

        @include breakpoint {
          padding: 20px;
        }
      }

      &__title {
        color: #702ED3;
        line-height: 32px;

        @include breakpoint(lg) {
          font-weight: 500;
          line-height: 48px;
        }

        @include breakpoint {
          font-size: 24px;
          font-weight: 400;
          line-height: 32px;
        }
      }

      &__text {
        color: #8F62F0;
      }

      &__button {
        background-color: #8F62F0;

        @include breakpoint(lg) {
          min-height: 48px;
        }
      }
    }
  }
}
