.sales {
  &__wrapper {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 0 40px 0;

    @include breakpoint {
      padding: 0 16px;
    }
  }

  &__stories-wrap {
    margin-top: 156px;
  }

  &__benefits {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #F0F0F0;
    margin-bottom: 16px;

    &-item {
      display: flex;
      align-items: center;

      &:not(:last-of-type) {
        margin-bottom: 24px;
      }
    }

    &-icon {
      @include size(64px);

      margin-right: 16px;
      flex-shrink: 0;
    }

    &-text {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #F0F0F0;
    }

    @include breakpoint {
      margin-bottom: 8px;

      &-text {
        font-size: 14px;
        line-height: 20px;
      }

      &-item {
        align-items: flex-start;

        &:not(:last-of-type) {
          margin-bottom: 12px;
        }
      }

      &-icon {
        @include size(36px);

        margin-right: 12px;
      }
    }
  }

  &__title {
    @extend %title-big;

    margin: 44px 0 24px;

    @include breakpoint(lg) {
      margin: 48px 0 24px;
    }

    @include breakpoint {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      margin: 32px 0 12px;
    }
  }

  &__marquee > div {
    align-items: flex-start;

    & > div {
      align-items: flex-start;
    }
  }

  &__review {
    padding: 24px;
    background-color: #36304A !important;
    border-radius: 32px;
    max-width: 426px;
    margin: 0 8px;

    &-wrap {
      position: relative;

      &:before,
      &:after {
        position: absolute;
        content: '';
        height: 100%;
        width: 174px;
        top: 0;
        left: 0;
        background: linear-gradient(270deg, #110F17 10.94%, rgba(17, 15, 23, 0) 100%);
        z-index: 1;
      }
      &:after {
        left: auto;
        right: 0;
      }
      &:before {
        transform: rotate(180deg);
      }

      @include breakpoint {
        height: 312px;

        &:before,
        &:after {
          display: none;
        }
      }
    }

    &-wrapper {
      margin: 180px 0 207px;

      @include breakpoint {
        margin: 120px 0 235px;
      }
    }

    &-title {
      @extend %title-big;

      margin-bottom: 38px;
      text-align: center;

      @include breakpoint {
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        margin-bottom: 32px;
      }
    }

    &-avatar {
      @include size(80px);

      border-radius: 50%;
      overflow: hidden;
      margin-right: 20px;

      img {
        @include size(100%);
      }
    }

    &-header {
      display: flex;
      margin-bottom: 16px;
    }

    &-name {
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
    }

    &-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #EDEAFD;
    }

    @include breakpoint {
      max-width: 296px;
    }
  }

  &__text {
    @extend %text;

    margin-bottom: 48px;

    @include breakpoint {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      margin-bottom: 12px;
    }

    &--small {
      color: #D1D1D1;

      @include breakpoint {
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        color: #E3E3E3;
        margin-bottom: 24px;
      }
    }
  }

  &__review-inner-wrap {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1439px) {
      display: block;
      max-width: 632px;
      margin: 0 auto;
      padding: 0 16px;
    }
  }

  &__answers-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -6px;
  }

  &__answer-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    margin-bottom: 6px;
    color: #E37B54;

    @include breakpoint {
      margin-bottom: 18px;
    }

    &--green {
      color: #00C236;
    }
  }

  &__plan-title {
    @extend %title-big;

    margin-bottom: 24px;

    @include breakpoint {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      text-align: left;
      margin-bottom: 12px;
    }
  }

  &__plan-subtitle {
    @extend %text;

    margin-bottom: 48px;

    @include breakpoint {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      margin-bottom: 12px;
    }
  }

  &__error {
    border-radius: 12px;
    color: #fff;
    background-color: #c83c38;
    position: fixed;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    z-index: 100;
  }

  &__button {
    @include flex(center);

    width: 100%;
    height: 56px;
    border-radius: 12px;
    background-color: #DC5A32;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: #fff;

    &--green {
      text-transform: uppercase;
      background-color: #00C236;
      height: 54px;
      border-radius: 32px;
      font-size: 20px;
    }

    &--orange-gradient {
      background: linear-gradient(180deg, #FFBD7E 0%, #FFA762 50%, #FFBD7E 100%);
      color: #333;
      font-weight: 800;
      height: 54px;
      border-radius: 32px;
      font-size: 20px;
    }
  }

  &__button-wrap {
    margin-top: 43px;

    @include breakpoint {
      margin-top: 18px;
    }
  }
  &__button-wrapper {
    margin-top: 8px;
  }
  &__link {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: center;
    color: #818181;
    margin: 24px 0 40px;
    display: block;

    @include breakpoint(lg) {
      margin: 24px 0 64px;
    }
    @include breakpoint(lg) {
      margin: 24px 0 88px;
    }
  }

  &--white {
    .sales {
      &__wrapper {
        max-width: 820px;

        @include breakpoint(lg) {
          max-width: 608px;
        }
      }

      &__title {
        color: #333;
        margin: 72px 0 16px;

        @include breakpoint(lg) {
          margin: 48px 0 12px;
        }
      }

      &__review {
        background-color: #F0F0F0 !important;
        border: 1px solid #E3E3E3
      }

      &__review-wrap {
        &:before,
        &:after {
          background: linear-gradient(270deg, #F7F7F7 10.94%, rgba(247, 247, 247, 0) 100%);
        }
      }

      &__review-title {
        color: #333;
      }

      &__review-text,
      &__review-name {
        color: #333;
      }

      &__text {
        color: #585858;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
      }
    }
  }
}
